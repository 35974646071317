'use client';
import { useCallback, useEffect, useRef, useState } from 'react';
import cx from 'classnames';

import { IconButton } from '@/lib/io-kit/IconButton';

import styles from './ModalDialog.module.scss';

export type ModalDialogProps = {
  className?: string;
  isOpen: boolean;

  // Hide close button
  // Default false
  hideCloseButton?: boolean;

  // Disable dialog dismissal when clicking outside or pressing the ESC key
  // Default false
  preventCancel?: boolean;

  children: React.ReactNode;
  footer?: React.ReactNode;

  'data-testid'?: string;

  onClose?: () => void;
};

export function ModalDialog({
  className,
  isOpen,
  hideCloseButton = false,
  preventCancel = false,
  children,
  footer,
  onClose,
  'data-testid': testId,
}: ModalDialogProps) {
  //TODO: Fix this as this forces the body to always have the top layer and causes tooltips to be behind
  const ref = useRef<HTMLDialogElement>(null);

  const [internalIsOpen, setInternalIsOpen] = useState(isOpen);
  if (isOpen !== internalIsOpen) {
    setInternalIsOpen(isOpen);
  }

  useEffect(() => {
    if (internalIsOpen) {
      ref.current?.showModal();
      document.body.classList.add(styles.withModalOpen); // prevent bg scroll
    } else {
      ref.current?.close();
      document.body.classList.remove(styles.withModalOpen);
    }
  }, [internalIsOpen]);

  useEffect(() => {
    document.body.classList.remove(styles.withModalOpen);
  }, []);

  useDialogCancellation(ref, preventCancel, onClose);

  return (
    <dialog className={cx(styles.modal, className)} ref={ref} data-testid={testId}>
      <div className={styles.modalContent}>
        {onClose && !hideCloseButton ? (
          <div className={styles.modalCloseButtonRow}>
            <span className={styles.modalCloseButtonWrapper}>
              <IconButton as="button" iconName="Close" size="large" aria-label="close" onClick={onClose} />
            </span>
          </div>
        ) : null}

        {children}
      </div>

      {footer ? <footer className={styles.modalFooter}>{footer}</footer> : null}
    </dialog>
  );
}

function useDialogCancellation(
  ref: React.MutableRefObject<HTMLDialogElement | null>,
  preventCancel: undefined | boolean,
  onClose?: () => void,
) {
  const handleCancel = useCallback(
    (evt: Event) => {
      if (preventCancel) {
        evt.preventDefault();
        return;
      }
      onClose?.();
    },
    [preventCancel, onClose],
  );

  const handleCloseOnClickOutside = useCallback(
    (evt: MouseEvent) => {
      if (!ref.current || !onClose) return;

      if (ref.current && wasTriggeredFromOutsideTheDialog(evt, ref.current) && !preventCancel) {
        onClose();
      }
    },
    [onClose, preventCancel, ref],
  );

  const closeRef = useRef(onClose);
  closeRef.current = onClose;

  useEffect(() => {
    if (!ref.current) return;
    const controller = new AbortController();
    const { signal } = controller;

    ref.current.addEventListener('cancel', handleCancel, { signal });
    ref.current.addEventListener('click', handleCloseOnClickOutside, { signal });

    return () => {
      controller.abort();
    };
  }, [handleCancel, handleCloseOnClickOutside, ref]);
}

function wasTriggeredFromOutsideTheDialog(e: MouseEvent, dialogElement: HTMLElement) {
  // check that click was outside (backdrop is ouside the boundaries but still a child node)
  if (isClickInsideRectangle(e, dialogElement)) return false;

  // if the event came from inside the dialog boundaries, check that it is not a children,
  // otherwise the dialog will be closed by button key presses
  if (dialogElement !== e.target && dialogElement.contains(e.target as HTMLElement)) return false;

  return true;
}

function isClickInsideRectangle(e: MouseEvent, dialogElement: HTMLElement) {
  const r = dialogElement.getBoundingClientRect();
  return e.clientX > r.left && e.clientX < r.right && e.clientY > r.top && e.clientY < r.bottom;
}
