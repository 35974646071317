'use client';

import { useMemo } from 'react';
import cx from 'classnames';
import OriginalSelect, { Props as SelectProps, ClassNamesConfig, GroupBase } from 'react-select';

import styles from './Select.module.scss';

type CustomProps = {
  className?: string;
  'data-testid'?: string;
  disabled?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  isMulti?: boolean;
  isSearchable?: boolean;
  placeholder?: string;
  error?: boolean;
};

export function Select<
  OptionType,
  IsMulti extends boolean = false,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
>({
  className,
  classNames,
  defaultValue,
  'data-testid': testId,
  disabled = false,
  isClearable = false,
  isLoading = false,
  isSearchable = true,
  name,
  options,
  placeholder,
  error = false,
  ...props
}: SelectProps<OptionType, IsMulti, GroupType> & CustomProps) {
  const selectClasses = cx(styles.container, { [styles.disabled]: disabled, [styles.hasError]: error }, className);

  /**
   * Custom styless object must be declared here and not at module level because
   * it needs access to the generics types
   */
  const classNamesStyles: ClassNamesConfig<OptionType, IsMulti, GroupType> = useMemo(
    () => ({
      container: (props) =>
        cx(styles.container, {
          [styles.isFocused]: props.isFocused,
          [styles.isDisabled]: props.isDisabled,
        }),
      control: () => cx(styles.control),
      dropdownIndicator: () => cx(styles.dropdownIndicator),
      indicatorSeparator: () => cx(styles.indicatorSeparator),
      input: () => cx(styles.input),
      menu: () => cx(styles.menu),
      menuList: () => cx(styles.menuList),
      option: (state) =>
        cx(styles.option, {
          [styles.optionFocused]: state.isFocused,
          [styles.optionSelected]: state.isSelected,
        }),
      placeholder: () => cx(styles.placeholder),
      singleValue: (props) => (props.isDisabled ? cx(styles.singleValueDisabled) : cx(styles.singleValue)),
      valueContainer: () => cx(styles.valueContainer),
      clearIndicator: () => cx(styles.clearIndicator),
      ...classNames,
    }),
    [classNames],
  );

  return (
    <OriginalSelect<OptionType, IsMulti, GroupType>
      {...props}
      className={selectClasses}
      classNames={classNamesStyles}
      classNamePrefix="io-select"
      data-testid={testId}
      defaultValue={defaultValue}
      isClearable={isClearable}
      isDisabled={disabled}
      isLoading={isLoading}
      isSearchable={isSearchable}
      name={name}
      options={options}
      placeholder={placeholder}
      unstyled // Have to have it unstyled in order to allow our styles to completely override the defaults
    />
  );
}
